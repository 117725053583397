<template>
    <div class="comp">
        <div class="comp-container">
            <div class="input-components">
                <h2> احراز هویت </h2>
                <PersonalInfo />
                <PhoneInfo />
                <BankInfo />
                <UploadPic />
                <button class="submit"> ثبت و ارسال اطلاعات </button>
            </div>
            <div class="guide">
                <img src="../../../assets/accountGuide.svg" alt="">
                <div class="guide-texts">
                    <div class="text-container">
                        <h3> اطلاعات شخصی </h3>
                        <span class="guide-body"> اطلاعات شخصی باید بر اساس مدارک شناسایی تکمیل شوند در غیر این صورت احراز هویت با خطا مواجه خواهد شد </span>
                    </div>
                    <div class="text-container">
                        <h3> اطلاعات تماس </h3>
                    </div>
                    <div class="text-container">
                        <h3> اطلاعات بانکی </h3>
                        <span class="guide-body"> حداقل یک حساب بانکی معرفی کنید </span>
                    </div>
                    <div class="text-container">
                        <h3> آپلود مدارک </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PersonalInfo from './PersnalInfo.vue'
import PhoneInfo from './PhoneInfo.vue'
import BankInfo from './BankInfo.vue'
import UploadPic from './UploadPic.vue'
    export default {
        name:'Notifications',
        components:{
            PersonalInfo,
            PhoneInfo,
            BankInfo,
            UploadPic
        },
        data() {
            return {
                   
            }
        },
    }
</script>

<style lang="scss" scoped>
.input-components{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    width: 30%;
}
.guide{
    display: flex;
    flex-direction: row;
    column-gap: 12px;
    width: 15%;
    .guide-texts{
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        span{
            font-size: clamp(10px,1vw,12px);
        }
    }
}
h2{
    font-size: clamp(16px,2vw,20px);
}
.comp-container{
    width: 100%;
    display: flex;
    flex-direction: row;
    row-gap: 16px;
    align-items: flex-start;
    justify-content: space-around;
}
.comp{
    min-height: 92vh;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
}
@media only screen and(max-width:1200px){
    .input-components{
        width: 50%;
    }
}
@media only screen and(max-width:1000px){
    .comp-container{
        flex-direction: column;
    }
    .input-components{
        width: 95%;
    }
    .guide{
        width: 100%;
        order: -1;
    }
}
</style>