<template>
    <div class="personal-info">
        <div class="title-container">
            <span> اطلاعات بانکی </span>
            <div class="addcard">
                <img class="addcard-img" src="../../../assets/icons/addcard.svg" alt="">
                <span class="addcardbtn"> اضافه کردن کارت </span>
            </div>
        </div>
        <div class="card-container">
            <div class="ac-input">
                <span v-if="data.cardNumber" class="active-span"> شماره کارت </span>
                <label for="">
                    <span v-if="!data.cardNumber"> شماره کارت </span>
                    <input v-model="data.cardNumber" type="text">
                </label>
            </div>
            <div class="ac-input">
                <span v-if="data.shebaCode" class="active-span"> شماره شبا </span>
                <label for="">
                    <span v-if="!data.shebaCode"> شماره شبا </span>
                    <input  v-model="data.shebaCode" type="text">
                </label>
            </div>
            <button class="submit addtocardsbtn"> افزودن کارت بانکی </button>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data:{
                    shebaCode:'',
                    cardNumber:''
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.addtocardsbtn{
    width: 40%;
}
.addcard-img{
    width: 12px;
    height: 12px;
}
.addcardbtn{ 
    color: var(--primary-color);
    font-size: clamp(12px,1vw,14px);
}
.addcard{
    cursor: pointer;
    display: flex;
    flex-direction: row;
    column-gap: 8px;
    align-items: center;
}
.card-container{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 12px;
    background: rgba(251, 251, 246, 1);
}
.ac-input{
    .bd{
        input{
            width: 100%;
        }
    }
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 100%;
    .active-span{
    color: var(--primary-color);
    }
    label{
        display: flex;
        flex-direction: row;
        background: var(--input-bg);
        padding: 8px 12px;
        border-radius: 4px;
        input{
            background: transparent;
            width: 80%;
        }
        span{
            font-weight: 400;
            font-size: clamp(12px,2vw,14px);
        }
    }
}
span{
    font-weight: 400;
    font-size: clamp(14px,2vw,18px);
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
.title-container{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
@media only screen and(max-width:500px){
    .submit{
        width: 100%;
    }
}
</style>