<template>
    <div class="personal-info">
        <span> اطلاعات شخصی </span>
        <div class="ac-input">
            <span v-if="data.firstName" class="active-span"> نام </span>
            <label for="">
                <span v-if="!data.firstName"> نام </span>
                <input @input="data.firstName=$S2Fa(data.firstName)" v-model="data.firstName" type="text">
            </label>
        </div>
         <div class="ac-input">
            <span v-if="data.firstName" class="active-span"> نام خانوادگی </span>
            <label for="">
                <span v-if="!data.lastName"> نام خانوادگی </span>
                <input @input="data.lastName=$S2Fa(data.lastName)" v-model="data.lastName" type="text">
            </label>
        </div>
         <div class="ac-input">
            <label class="bd" for="">
                <date-picker 
                        placeholder=" تاریخ تولد " 
                        v-model="data.birthDay" 
                        :format="$dateFormat['en']"
                        :display-format="$dateFormat['fa']"
                        :clearable="!state.userInfo.authorized"
                        :class="'date-fa'"
                        class="date"/>
            </label>
        </div>
         <div class="ac-input">
            <span v-if="data.nationalCode" class="active-span"> کد ملی </span>
            <label for="">
                <span v-if="!data.nationalCode"> کد ملی </span>
                <input @input="data.nationalCode=$S2Fa(data.nationalCode)" v-model="data.nationalCode" type="text">
            </label>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                data:{
                    firstName:'',
                    lastName:'',
                    birthDay:'',
                    nationalCode:''
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.ac-input{
    .bd{
        input{
            width: 100%;
        }
    }
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    width: 100%;
    .active-span{
    color: var(--primary-color);
    }
    label{
        display: flex;
        flex-direction: row;
        background: var(--input-bg);
        padding: 8px 12px;
        border-radius: 4px;
        input{
            background: transparent;
            width: 80%;
        }
        span{
            font-weight: 400;
            font-size: clamp(12px,2vw,14px);
        }
    }
}
span{
    font-weight: 400;
    font-size: clamp(14px,2vw,18px);
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
</style>