<template>
    <div class="personal-info">
        <span> آپلود مدارک </span>
        <div class="explanations">
            <p>
                لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا واضح و خوانا دقیقا مانند تصویر نمونه ارسال نمایید
                 دقت شود متن دست نوشته نبایستی تایپ شودهمچنین توجه داشته باشیدهنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود
            </p>
            <div class="handwriting">
                <span> متن دست نوشته : </span>
                <p class="handwrite-text">
                   اینجانب (نام و نام خانوادگی) به کدملی(کد ملی) ضمن مطالعه و تایید قوانین استفاده از خدمات زیین کارنسی، متعهد میگردم که حساب کاربری و مدارک خود را به منظور خرید و فروش ارز های دیجیتال در اختیار سایر افراد قرار ندهم. 
                </p>
            </div>
            <div class="upload-spot">
                <span> محل آپلود تصویر </span>
                <div class="pics">
                    <div class="examples">
                        <img src="../../../assets/selfie2.svg" alt="">
                        <img src="../../../assets/selfie1.svg" alt="">
                    </div>
                    <button class="submit"> آپلود تصویر </button>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
    export default {
        data() {
            return {
                data:{
                    firstName:'',
                    lastName:'',
                    birthDay:'',
                    nationalCode:'',
                    validationHelp1:'لطفا تصویر کارت ملی را در کنار چهره خودتان همراه با یک دست نوشته به صورت کاملا واضح و خوانا دقیقا مانند تصویر نمونه ارسال نمایید',
                    validationHelp2:' دقت شود متن دست نوشته نبایستی تایپ شودهمچنین توجه داشته باشیدهنگام تهیه تصویر، متن دست نوشته و اطلاعات کارت ملی به صورت برعکس و آینه ای ثبت نشود',
                    validationHandWrite2:'اینجانب (نام و نام خانوادگی) به کدملی(کد ملی) ضمن مطالعه و تایید قوانین استفاده از خدمات زیین کارنسی، متعهد میگردم که حساب کاربری و مدارک خود را به منظور خرید و فروش ارز های دیجیتال در اختیار سایر افراد قرار ندهم.',
                }
            }
        },
    }
</script>

<style lang="scss" scoped>
.submit{
    max-width: 140px;
}
.examples{
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}
.pics{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    align-items: center;
    padding: 24px;
    width: 100%;
    border: 2px dashed #888E95;
    border-radius: 12px;
    background: linear-gradient(180deg, rgba(219, 210, 188, 0.15) 0%, rgba(222, 214, 195, 0.41) 100%);
}
.upload-spot{
    display: flex;
    flex-direction: column;
    row-gap: 16px;
}
.handwriting{
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    .handwrite-text{
        color: rgba(0, 17, 106, 1);
    }
}
.explanations{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}
span{
    font-weight: 400;
    font-size: clamp(14px,2vw,18px);
}
.personal-info{
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    padding: 24px;
    background: white;
    border-radius: 12px;
    width: 100%;
}
@media only screen and(max-width:500px){
    .examples{
        flex-direction: column;
        gap: 16px;
    }
}
</style>